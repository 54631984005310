///* ========================================================================
//   #NAV
//   ======================================================================== */


$nav-item-margin: 0 40px 0 0 !default;


$nav-link-color: #9FA9BA !default;
$nav-link-active-color: $link-color !default;
$nav-link-font-size: $text-font-size !default;
$nav-link-font-weight: $font-weight-normal !default;


// Nav toggle (hamburger icon)
$nav-toggle-width: 30px !default; // [1] same as bar's width
$nav-toggle-height: 30px !default;

// Nav toggle bar
$nav-toggle-bar-width: 30px !default; // [1] same as icon's width
$nav-toggle-bar-height: 4px !default;
$nav-toggle-bar-border-radius: $base-border-radius !default;
$nav-toggle-bar-background-color: $gunmetal-200 !default; // the opposite of the background color

