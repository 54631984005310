///* ========================================================================
//   #TOOLBAR
//   ======================================================================== */

$toolbar-padding: 20px 30px !default;
$toolbar-background-color: $base-background-color !default;
$toolbar-border-color: #E6EAEE !default;
$toolbar-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07) !default;

$toolbar-title-color: $heading-color !default;
$toolbar-title-font-size: 18px !default;

$toolbar-divider-width: 1px !default;
$toolbar-divider-height: 15px !default;
$toolbar-divider-margin: 0 13px !default;
$toolbar-divider-background-color: #DFE3E9 !default;

$toolbar-meta-font-size: 14px !default;
$toolbar-meta-color: #7F8FA4 !default;

$toolbar-icon-color: #CED0DA !default;
$toolbar-icon-font-size: 18px !default;

$toolbar-link-color: #7F8FA4 !default;
$toolbar-link-font-size: 14px !default;




