/* ==========================================================================
   #BOOTSTRAP UTILITIES
   ========================================================================== */

/**
 * These classes are used by bootstrap plugins.
 */

.fade {
	transition: opacity .3s;
	opacity: 0;

	&.show {
		opacity: 1;
	}
}


