///* ========================================================================
//   #NAVBAR
//   ======================================================================== */

//
// 1. Customize it to suit your brand's dimensions
//
$navbar-height: 70px !default;

$navbar-padding: 13px 30px !default;
$navbar-background-color: $white !default;
$navbar-border-width: 1px !default;
$navbar-border-color: #E6EAEE !default;

$navbar-brand-width: 44px !default; // [1]
$navbar-brand-height: 44px !default; // [1]
$navbar-brand-margin: 0 20px 0 0 !default;
