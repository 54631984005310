///* ========================================================================
//   #BREADCRUMB
//   ======================================================================== */


$breadcrumb-divider-color: #a1a7aF !default;
$breadcrumb-divider-margin: 0 $spacer-xsmall !default;

$breadcrumb-item-color: #848C98 !default;
$breadcrumb-item-active-color: $gunmetal-200 !default;

