/* ==========================================================================
   #JQV MAP CUSTOM
   ========================================================================== */

/*
 * A quick fix for jqvmap pins (not positioned properly)
 */

.jqvmap-pin {
	transform: translate(-3200%, -1050%);

	@include respond-to($breakpoint-desktop) {
		transform: translate(-300%, -1050%);
	}

	@include respond-to($breakpoint-tablet) {
		transform: translate(-500%,-1500%);
	}
}
