/* ==========================================================================
   #PLANS 
   ========================================================================== */

.c-plan {
	margin: 0 5px 30px;
	padding: 40px 20px 25px;
	border: 1px solid $base-border-color;
	border-radius: $base-border-radius;
	background-color: transparent;
	text-align: left;
}

.c-plan__img {
	position: absolute;
	top: -20px;
}

.c-plan__title {
	margin: 0;
	color: $color-mute;
	font-size: 12px;
	text-transform: uppercase;
}

.c-plan__price {
	margin: 0;
	font-size: 28px;
}

.c-plan__note {
	margin: 0;
	font-size: $text-small-font-size;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
}

.c-plan__divider {
	display: block;
	width: 100%;
	height: 1px;
	margin: 15px 0;
	background-color: $base-border-color;
}

.c-plan__feature {
	margin-bottom: 5px;
	color: $color-mute;
	font-size: $text-font-size;
}
