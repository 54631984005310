///* ========================================================================
//   #DIVIDER
//   ======================================================================== */

$divider-color: $grey-800 !default;
$divider-font-size: $text-small-font-size !default;
$divider-font-weight: $font-weight-normal !default;

$divider-line-height: 1px !default;
$divider-line-background-color: $grey-400 !default;

