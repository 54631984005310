/* ==========================================================================
   #TODO
   ========================================================================== */

/**
 * 1. Get rid of white space cause by using `inline-flex`.
 * 
 * Read More: https://goo.gl/ajXW52
 */

.c-todo {
	display: flex;
	justify-content: space-between;
	padding: rem(20px) $spacer;
	border-top: $base-border-width solid $base-border-color;
	font-size: 0; /* [1] */
	font-weight: $choice-font-weight;

	/**
	 * Disabled state
	 *
	 * NOTE: `disabled` attribute must be added to <input>
	 */
	&.is-disabled {
		.c-todo__label {
			color: $choice-disabled-color;
			cursor: default;

			&::before {
				border-color: $choice-disabled-border-color;
				background-color: $choice-disabled-background-color;
				text-decoration: none;
			}
		}
	}
}

/**
 * 1. Hide default input and create a customized input.
 * 2. Align the customized input with label.
 */
.c-todo__input { /* [1] */
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	white-space: nowrap;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);

	& + .c-todo__label {
		display: inline-flex;
		position: relative;
		align-items: center;
		padding-left: rem(25px);
		color: $heading-color;
		font-size: $text-font-size;
		cursor: pointer;

		.c-todo.is-completed & {
			text-decoration: line-through;
			opacity: .5;
 		}
	}

	& + .c-todo__label::before {
		display: inline-block;
		position: absolute;
		top: 2px; /* [2] */
		left: 0;
		width: 16px;
		height: 16px;
		margin: 0 15px 0 0;
		transition: all .3s;
		border: $base-border-width solid $grey-600;
		border-radius: 100%;
		background-color: $white;
		color: $white;
		font-family: $base-icon-font;
		font-size: 10px;
		line-height: 15px;
		text-align: center;
		text-decoration: none;
		content: "";
		visibility: visible; /* [2] */
	}

	// custom designed checkbox
	&:checked + .c-todo__label::before {
		border-color: $grey-600;
		background-color: $grey-600;
		color: $white;
		content: "\f00c"; // fa-check
	}
}


/* Todo Stream
   ========================================================================== */

/**
 * Todo task displayed as a stream. Used in active-projects.html page.
 */

.c-todo-stream {
	display: block;
	position: relative;

	&::before {
		position: absolute;
		top: 7.5%;
		left: 7px; // [width of (checkbox) input / 2] - 1px
		width: 2px;
		height: 85%;
		background-color: $grey-600;
		content: "";
		opacity: 0.2;
	}

	.c-todo {
		margin-bottom: 15px;
		padding: 0;
		border-top: 0;
	}
}
