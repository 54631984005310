/* ==========================================================================
   #TOGGLE
   ========================================================================== */

/**
 * Toggles are a pair of buttons useful for (Yes/No)-like questions.
 *
 * 1. Remove `is-active` styles when `c-toggle` is disabled
 */

.c-toggle {
	display: flex;
	align-content: center;
	align-items: center;
	width: 100%;
	height: $toggle-height;
	cursor: pointer;

	&.is-disabled {
		cursor: not-allowed;
		opacity: 0.5;

		.c-toggle__btn.is-active { /* [1] */
			border-color: $toggle-btn-border-color;
			background-color: $toggle-btn-background-color;
			color: $toggle-btn-color;
		}
	}
}

.c-toggle__btn {
	width: $toggle-btn-width;
	height: $toggle-btn-height;
	transition: all .3s;
	border: $toggle-btn-border-width solid $toggle-btn-border-color;
	border-radius: $toggle-btn-border-radius;
	background-color: $toggle-btn-background-color;
	color: $toggle-btn-color;
	font-size: $toggle-btn-font-size;
	text-align: center;
	text-transform: uppercase;
	cursor: inherit;

	&:nth-child(1) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&:nth-child(2) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&.is-active {
		border-color: $toggle-btn-active-border-color;
		background-color: $toggle-btn-active-background-color;
		color: $toggle-btn-active-color;
	}
}

.c-toggle__label {
	width: $toggle-label-width;
	height: $toggle-label-height;
	line-height: $toggle-label-line-height;
	cursor: inherit;
}


/**
 * 1. Hide default radio button
 */

.c-toggle__input {
	display: none; /* [1] */
	width: 100%;
	height: 100%;
}
