/* ==========================================================================
   #SWITCH
   ========================================================================== */

/**
 * 1. Radius of `toggle` should be equal to radius of `c-switch`.
 * 2. The small circle that move to the right and left.
 * 3. Calculation: (width of switch bar) - (toggle's width + 2px )
 * 4. Don't allow active state on a disabled switch.
 */

.c-switch {
	display: inline-flex;
	position: relative;
	flex-wrap: wrap;
	align-items: center;
	cursor: pointer;

	&::before {
		display: inline-block;
		position: relative;
		width: $switch-width;
		height: $switch-height;
		transition: all .3s;
		border: 1px solid $base-border-color;
		border-radius: $switch-border-radius;
		background-color: $switch-background-color;
		content: " ";
	}

	&::after { /* [2] */
		position: absolute;
		top: 3px;
		left: 3px;
		width: $switch-toggle-width;
		height: $switch-toggle-height;
		transition: all .3s;
		border-radius: $switch-border-radius; /* [1] */
		background-color: $switch-toggle-background-color;
		content: " ";
	}

	// switch is (on)
	&.is-active {

		&::before {
			background-color: $switch-active-background-color;
		}

		&::after {
			left: 25px; /* [3] */
		}
	}

	&.is-disabled {
		cursor: not-allowed;

		&::after {
			opacity: 0.5;
		}

		&.is-active { /* [4] */
			&::before {
				background-color: $switch-background-color;
			}

			&::after {
				right: 2px;
			}
		}
	}
}


/**
 * 1. Hide input to replace it with switch-style input
 */

.c-switch__input {
	display: none; /* [1] */
}

.c-switch__label {
	margin: $switch-label-margin;
	color: $switch-label-color;
	font-size: $switch-label-font-size;
	user-select: none;
}
