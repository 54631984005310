///* ========================================================================
//   #TABS
//   ======================================================================== */


// Tabs (wrapper)
$tabs-list-padding: 20px 30px !default;
$tabs-list-background-color: $white !default;
$tabs-list-border-color: $grey-400 !default;

$tabs-list-border-width: 1px !default;
$tabs-list-border-radius: $base-border-radius !default;


// Individual tab, tab link
$tabs-item-margin: 0 30px 0 0 !default;

$tabs-link-padding: 0 0 21px 0 !default; // to override tab header's border-bottom
$tabs-link-font-size: $h6-font-size !default;
$tabs-link-color: $grey-800 !default;

$tabs-link-hover-color: $gunmetal-200 !default;

$tabs-link-active-color: $gunmetal-300 !default;
$tabs-link-active-border-width: 2px !default;
$tabs-link-active-border-color: $blue-300 !default;


// Tab content
$tabs-pane-background-color: $white !default;
$tabs-pane-margin: 0 !default;
$tabs-pane-padding: 20px 30px !default;
$tabs-pane-border-radius: $base-border-radius !default;
$tabs-pane-border-color: $grey-400 !default;
$tabs-pane-border-width: 1px !default;
$tabs-pane-color: $text-color !default;

// Splitted Tabs

$tabs-splitted-link-gradient: #fff, #F4F7FA !default;














