/* ==========================================================================
   #EVENT
   ========================================================================== */

.c-event {
	margin-bottom: $spacer;
	padding: $spacer-xsmall;
	border: $base-border-width solid $base-border-color;
	border-radius: $base-border-radius;
	background: $base-background-color;
}

.c-event__img {
	position: relative;
	margin-bottom: $spacer-small;
	border-radius: 3px;
	overflow: hidden;

	> img {
		display: block;
	}
}

.c-event__status {
	position: absolute;
	top: $spacer-xsmall;
	right: $spacer-xsmall;
	padding: 5px 12px;
	border-radius: $base-border-radius;
	background: rgba(49, 53, 63, .25);
	color: $white;
	font-size: $text-small-font-size;
	font-weight: $font-weight-semibold;
	text-transform: uppercase;
}


/** 
 * Meta information about the event
 */

.c-event__meta {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	padding: 0 10px 0 20px;
}

.c-event__title {
	margin: 0;
	font-size: $h6-font-size;
	font-weight: $font-weight-semibold;
}

.c-event__place {
	display: block;
	margin: 0;
	color: $color-mute;
	font-size: $text-small-font-size;
}


/**
 * Align event button with the event title
 */

.c-event__btn {
	display: inline-flex;
	align-items: center;
}
