///* ========================================================================
//   #POPOVERS
//   ======================================================================== */


$popover-max-width: rem(300px);
$popover-padding: $spacer-small 20px;
$popover-border-radius: $base-border-radius;

$popover-background-color: $gunmetal-500;
$popover-color: $white;

$popover-font-size: $text-font-size;
$popover-font-weight: $font-weight-normal;
