///* ========================================================================
//   #DROPDOWN
//   ======================================================================== */


$dropdown-menu-width: rem(150px) !default;
$dropdown-menu-large-width: rem(300px) !default;
$dropdown-menu-background-color: $white !default;

$dropdown-menu-border-width: $base-border-width !default;
$dropdown-menu-border-color: $grey-400 !default;
$dropdown-menu-border-radius: $base-border-radius !default;
$dropdown-menu-box-shadow: 0 1px 4px rgba(0, 0, 0, .08);

$dropdown-item-padding: rem(8px) $spacer-small !default;
$dropdown-item-large-padding: $spacer-xsmall $spacer-small !default;
$dropdown-item-font-size: $text-font-size !default;
$dropdown-item-font-weight: $font-weight-normal !default;
$dropdown-item-color: $gunmetal-200 !default;
$dropdown-item-border-color: $grey-400 !default;
$dropdown-item-border-width: $base-border-width !default;

$dropdown-item-hover-color: $blue-300 !default;
$dropdown-item-hover-background-color: $grey-200 !default;
