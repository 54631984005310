/* ==========================================================================
   #FLOATS UTILITIES
   ========================================================================== */

// sass-lint:disable no-important

.u-float-left {
	float: left !important;
}

.u-float-right {
	float: right !important;
}
