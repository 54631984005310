/* ==========================================================================
   #BORDER UTILITIES
   ========================================================================== */

// sass-lint:disable no-important

/**
 * Use border utilities to add or remove borders from certain components 
 * to prevent border collapsing.
 */


/**
 * Add borders
 */

.u-border-top {
	border-top: $base-border-width solid $base-border-color !important;
}

.u-border-right {
	border-right: $base-border-width solid $base-border-color !important;
}

.u-border-bottom {
	border-bottom: $base-border-width solid $base-border-color !important;
}

.u-border-left {
	border-left: $base-border-width solid $base-border-color !important;
}


/**
 * Remove borders
 */

.u-border-zero {
	border: 0 !important;
}

.u-border-top-zero {
	border-top: 0 !important;
}

.u-border-right-zero {
	border-right: 0 !important;
}

.u-border-bottom-zero {
	border-bottom: 0 !important;
}

.u-border-left-zero {
	border-left: 0 !important;
}


/**
 * Border radius
 */

.u-border-rounded {
	border-radius: $base-border-radius;
	overflow: hidden;
}

.u-border-circle {
	border-radius: 100%;
	overflow: hidden;
}

