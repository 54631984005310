/* ==========================================================================
   #NAVBAR
   ========================================================================== */

/**
 * The site's main page header, used as a wrapper for its sub-components.
 */

.c-navbar {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	min-width: 100%;
	min-height: $navbar-height;
	padding: $navbar-padding;
	border-bottom: $navbar-border-width solid $navbar-border-color;
	background-color: $navbar-background-color;

	@include respond-to($breakpoint-tablet){
		padding: $spacer-xsmall $spacer-small;
	}
}


/**
 * Set a fixed width for search input when placed in navbar.
 */

.c-navbar__search {
	width: 270px !important;
}

/**
 * 1. Custom width, height is specififed for proper alignment, adding your own 
 *    brand/logo image will require custom styles for proper alignment.
 * 2. On small screens, seperate logo/brand from other navbar components as 
 *    navbar links would be collapsed.
 */

.c-navbar__brand {
	display: inline-block;
	width: $navbar-brand-width; /* [1] */
	height: $navbar-brand-height; /* [1] */
	margin: $navbar-brand-margin;

	@include respond-to($breakpoint-tablet){
		margin-right: auto; /* [2] */
	}
}

.c-navbar__title {
	margin: 0;
	padding: 0;
	font-size: $h5-font-size;
}


/* Style variants
   ========================================================================== */

/**
 * Use `c-navbar--inverse` for dark background colors.
 *
 */

.c-navbar--inverse {
	@include linear-gradient(to bottom, $blue-300, $blue-400);

	.c-nav,
	.c-nav__item {
		border-color: $blue-200;
	}

	.c-nav__link {
		color: $white;
	}

	.c-avatar.has-dropdown::after {
		color: $white;
	}

	.c-nav-toggle__icon,
	.c-nav-toggle__icon::before,
	.c-nav-toggle__icon::after {
		background-color: $base-background-color;
		opacity: 0.95;
	}
}
