/* ==========================================================================
   #RATIO OBJECT
   ========================================================================== */

/**
 * Ratio object is used to keep media (e.g. images, videos) in
 * their correct aspect ratios.
 *
 * USAGE: 
 * <div class="o-ratio o-ratio--16:9">
 *     <iframe src"/"></iframe>
 * </div>
 */


/**
 * 1. Default cropping is a 1:1 ratio (i.e. a perfect square)
 */

.o-ratio {
	display: block;
	position: relative;

	&::before {
		display: block;
		width: 100%;
		padding-bottom: 100%; /* [1] */
		content: "";
	}
}

.o-ratio__content,
.o-ratio > iframe,
.o-ratio > embed,
.o-ratio > object {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.o-ratio--16\:9::before {
	padding-bottom: (9 / 16) * 100%;
}

.o-ratio--4\:3::before {
	padding-bottom: (3 / 4) * 100%;
}

.o-ratio--2\:1::before {
	padding-bottom: (1 / 2) * 100%;
}
