/* ==========================================================================
   #COLORS UTILITIES
   ========================================================================== */

// sass-lint:disable no-important

//
// UNDER DEVELOPMENT: Generate these clases using scss methods
//

/**
 * Set the text color of elements.
 */

.u-color-success {
	color: $color-success !important;
}

.u-color-info {
	color: $color-info !important;
}

.u-color-danger {
	color: $color-danger !important;
}

.u-color-warning  {
	color: $color-warning !important;
}

.u-color-primary {
	color: $heading-color !important;
}

.u-color-secondary {
	color: $white !important;
}

.u-color-white {
	color: $white !important;
}


/**
  * Set the background color of elements.
  */

.u-bg-success {
	background: $green-200 !important;
	background-color: $green-200 !important;
}

.u-bg-info {
	background: $blue-500 !important;
	background-color: $blue-500 !important;
}

.u-bg-danger {
	background: $red-400 !important;
	background-color: $red-400 !important;
}

.u-bg-fancy {
	background: $purple-200 !important;
	background-color: $purple-200 !important;
}

.u-bg-warning {
	background: $orange-300 !important;
	background-color: $orange-300 !important;
}

.u-bg-primary {
	background: $gunmetal-100 !important;
	background-color: $gunmetal-100 !important;
}

.u-bg-secondary {
	background: $secondary-background-color !important;
	background-color: $secondary-background-color !important;
}

.u-bg-white {
	background: $white !important;
	background-color: $white !important;
}


/**
  * Use brand-color utilities to apply text colors and background colors to 
  * brand-related components like social icons.
  */

/**
 * Brand text colors
 */

.u-color-facebook {
	color:  $color-facebook !important;
}

.u-color-twitter {
	color:  $color-twitter !important;
}

.u-color-pinterest {
	color:  $color-pinterest !important;
}

.u-color-behance {
	color: $color-behance !important;
}

.u-color-dribbble {
	color: $color-dribbble !important;
}

.u-color-linkedin {
	color: $color-linkedin !important;
}


/**
 * Brand background colors
 *
 * 1. If a component has border, we change its border-color to match with
 *    the brand background-color.
 */

.u-bg-facebook {
	border-color: $color-facebook !important; /* [1] */
	background:  $color-facebook !important;
}

.u-bg-twitter {
	border-color: $color-twitter !important;
	background:  $color-twitter !important;
}

.u-bg-pinterest {
	border-color: $color-pinterest !important;
	background:  $color-pinterest !important;
}

.u-bg-behance {
	border-color: $color-behance !important;
	background: $color-behance !important;
}

.u-bg-dribbble {
	border-color: $color-dribbble !important;
	background: $color-dribbble !important;
}

.u-bg-linkedin {
	border-color: $color-linkedin;
	background-color: $color-linkedin !important;
}
