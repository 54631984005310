/* ==========================================================================
   #CARD
   ========================================================================== */

/**
 * A simple component to apply card-like style, used with genearal widgets that 
 * have sub-components in it.
 */

.c-card {
	position: relative;
	border: $card-border-width solid $card-border-color;
	border-radius: $card-border-radius;
	background-color: $card-background-color;
}


/**
 * 1. Display a scrollbar if the content of the card is larger than the 
 *    card's width.
 */

.c-card--responsive {
	overflow: auto; /* [1] */
}


/**
 * 1. Align header's content vertically in the center
 */

.c-card__header {
	padding: $card-header-padding;
	border-bottom: $card-border-width solid $card-border-color;
	background-color: $card-header-background-color;
}


/**
 * Remove default background-color of card's header
 */

.c-card__header--transparent {
	background-color: transparent;
}

.c-card__title {
	margin: 0;
	font-size: $card-title-font-size;
}

.c-card__meta {
	color: $card-meta-color;
	font-size: $card-meta-font-size;
}

.c-card__icon {
	display: flex;
	position: absolute;
	top: -35px;
	right: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 70px;
	margin: 0 auto;
	border-radius: 100%;
	color: $card-icon-color;
	font-size: $card-icon-font-size;
	@include linear-gradient(to bottom, $gradient-info);

	&:hover i {
		color: inherit;
		text-decoration: none;
	}
}

.c-card__body {
	padding: $card-body-padding;
}
