///* ========================================================================
//   #CLOSE
//   ======================================================================== */


$close-color: $white;
$close-font-size: rem(26px);
$close-opacity: 0.7;



