///* ========================================================================
//   #RANGE
//   ======================================================================== */

$range-thumb-width: 10px !default;
$range-thumb-height: 10px !default;

$range-thumb-gradient: #2DA1F8, #1991EB !default;

$range-track-height: 2px !default;
$range-track-border-radius: 1px !default;
$range-track-background-color: #CED0DA !default;
