///* ========================================================================
//   #AVATARS
//   ======================================================================== */


$avatar-img-width: 70px !default;
$avatar-img-height: 70px !default;

// Avatar with dropdown arrow/menu
$avatar-arrow-color: $grey-800 !default;

// Size variants
$avatar-img-xsmall-width: 36px !default;
$avatar-img-xsmall-height: 36px !default;

$avatar-img-small-width: 50px !default;
$avatar-img-small-height: 50px !default;

$avatar-img-medium-width: 60px !default;
$avatar-img-medium-height: 60px !default;

$avatar-img-large-width: 90px !default;
$avatar-img-large-height: 90px !default;

$avatar-img-xlarge-width: 110px !default;
$avatar-img-xlarge-height: 110px !default;

$avatar-img-super-width: 130px !default;
$avatar-img-super-height: 130px !default;
