/* ==========================================================================
   #LINE OBJECT
   ========================================================================== */

/**
 * The line object is used to align elemnts on one line, obviously.
 */

.o-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
