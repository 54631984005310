/* ==========================================================================
   #SIZES UTILITIES
   ========================================================================== */

// sass-lint:disable no-important

/**
 * Widths
 */

.u-width-25 {
	width: 25% !important;
}

.u-width-50 {
	width: 50% !important;
}

.u-width-75 {
	width: 75% !important;
}

.u-width-100 {
	width: 100% !important;
}


/**
 * Heights
 */

.u-height-25 {
	height: 25% !important;
}

.u-height-50 {
	height: 50% !important;
}

.u-height-75 {
	height: 75% !important;
}

.u-height-100 {
	height: 100% !important;
}
